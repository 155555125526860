<template>
  <div
    class="userservice"
    :style="{ height: clientHeight - 180 + 'px' }"
    style="overflow-y: scroll"
  >
    <!-- 搜索 -->
    <el-form ref="form" :model="form" label-width="90px">
      <el-form-item label="接口路径">
        <el-input v-model="form.url"></el-input>
      </el-form-item>
      <el-form-item label="发起时间">
        <el-date-picker
          v-model="form.time"
          type="datetimerange"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          format="yyyy-MM-dd HH:mm:ss"
          value-format="yyyy-MM-dd HH:mm:ss"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <img src="../../../assets/search.png" alt="" @click="searchList" />
      </el-form-item>
    </el-form>
    <!-- 表格 -->
    <div class="tables">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="ipAddr" label="请求IP地址" width="155">
          <template slot-scope="scope">
            <span v-if="scope.row.ipAddr === null || scope.row.ipAddr === ''"
              >--</span
            >
            <span v-else>{{ scope.row.ipAddr }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="interfaceName" label="接口名称" width="230">
          <template slot-scope="scope">
            <span
              v-if="
                scope.row.interfaceName === null ||
                scope.row.interfaceName === ''
              "
              >--</span
            >
            <span v-else>{{ scope.row.interfaceName }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="interfaceUrl" label="接口路径" width="230">
          <template slot-scope="scope">
            <span
              v-if="
                scope.row.interfaceUrl === null || scope.row.interfaceUrl === ''
              "
              >--</span
            >
            <span v-else>{{ scope.row.interfaceUrl }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="errStatus" label="调用状态" width="150">
          <template slot-scope="scope">
            <span
              v-if="scope.row.errStatus === null || scope.row.errStatus === ''"
              >--</span
            >
            <span v-else>{{ scope.row.errStatus }}</span>
          </template>
        </el-table-column> -->
        <el-table-column prop="errReason" label="返回结果" width="100">
          <template slot-scope="scope">
            <span
              v-if="scope.row.errReason === null || scope.row.errReason === ''"
              >--</span
            >
            <span v-else>{{ scope.row.errReason }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="result" label="返回参数">
          <template slot-scope="scope">
            <span v-if="scope.row.result === null || scope.row.result === ''"
              >--</span
            >
            <span v-else>{{ scope.row.result }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="createDate" label="调用日期" width="180">
          <template slot-scope="scope">
            <span
              v-if="
                scope.row.createDate === null || scope.row.createDate === ''
              "
              >--</span
            >
            <span v-else>{{ scope.row.createDate }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <el-button
              class="submitBtn"
              type="primary"
              @click="handleDelete(scope.$index, scope.row)"
              >参数</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 总结 -->
    <div class="total">
      <!-- 分页 -->
      <div class="right">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-size="pageSize"
          :current-page="pageCount"
          layout="total, prev, pager, next"
          :total="totalCount"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 按钮 -->
    <div class="buttons">
      <el-button>数据分析</el-button>
      <el-button @click="exportHis">数据导出</el-button>
    </div>
    <div>
      <el-dialog
        title="参数"
        :visible.sync="dialogVisible"
        width="45%"
        :before-close="handleClose"
        :lock-scroll="false"
      >
        <el-form ref="formOne" :model="formOne" label-width="auto">
          <el-form-item label="参数">
            <!-- <json-viewer
              :value="JSON.parse(jsonStr)"
              :expand-depth="5"
              boxed
              sort
              :show-array-index="false"
              copyable
              theme="my-awesome-json-theme"
            >
              <template slot="copy">
                <i class="el-icon-document-copy" title="复制"></i>
              </template>
            </json-viewer> -->

            <span>{{ formOne.parameters }}</span>
            <!-- <el-input
              v-model="formOne.parameters"
              placeholder=""
              @input="$forceUpdate()"
            ></el-input> -->
          </el-form-item>
          <!-- <div class="xian1"></div> -->
        </el-form>
        <!-- <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="dialogVisible = false"
            >确 定</el-button
          >
        </span> -->
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { apiShan } from "../../../api1/shanConfig";
export default {
  data() {
    return {
      jsonStr:
        '{\r\n    "success": true,\r\n    "code": 200,\r\n    "msg": "操作成功",\r\n    "data": ""\r\n}',
      dialogVisible: false,
      clientHeight: document.body.clientHeight,
      // 分页
      pageSize: 10,
      totalCount: 1,
      pageCount: 1,
      // 搜索
      input: "",
      // 下拉
      value: "",
      //   表格
      tableData: [],
      timeArry: [],
      form: {
        url: "",
        userNo: null,
        userNames: "",
      },
      formOne: {
        createDate: "",
        errReason: "",
        interfaceUrl: "",
        interfaceName: "",
        ipAddr: "",
        parameters: "",
      },
    };
  },
  created() {
    this.getList();
  },
  mounted() {
    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenHeight = document.body.clientHeight;
        that.clientHeight = window.screenHeight;
      })();
    };
  },
  watch: {
    clientHeight(val) {
      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      if (!this.timer) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.clientHeight = val;
        this.timer = true;
        let that = this;
        setTimeout(function () {
          // 打印screenWidth变化的值
          console.log(that.clientHeight);
          that.timer = false;
        }, 400);
      }
    },
  },
  methods: {
    handleDelete(index, row) {
      console.log("输出详情：", index, row);
      this.formOne = row;
      this.dialogVisible = true;
    },
    handleClose() {
      this.dialogVisible = false;
    },
    exportHis() {},
    aa(val) {
      console.log("val:", val);
      this.timeArry = val;
    },
    searchList() {
      this.getList();
    },
    getList() {
      apiShan
        .getFace({
          pageSize: this.pageSize,
          pageCount: this.pageCount,
          url: this.form.url,
          startTime: this.form.time == null ? "" : this.form.time[0],
          endTime: this.form.time == null ? "" : this.form.time[1],
        })
        .then((res) => {
          // console.log("400中心：", res.data);
          if (res.data.code == 200) {
            this.tableData = res.data.result.data;
            this.totalCount = res.data.result.page.totalCount;
            this.pageSize = res.data.result.page.pageSize;
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.pageCount = val;
      this.getList();
    },
    // 弹框详情
    details() {},
  },
};
</script>

<style lang="less" scoped>
/deep/.el-form {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  margin-left: 25px;
}
/deep/.el-input__inner {
  height: 33px;
  line-height: 33px;
  padding: 0 10px;
}
/deep/.el-form-item__label {
  text-align: left;
}
/deep/.el-form-item {
  margin-right: 26px;
}

.userservice {
  .search {
    height: 80px;
    margin: 0 40px;
    margin-left: 25px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .span1 {
      display: block;
      font-size: 14px;
      width: auto;
      color: #909399;
      margin-right: 1%;
      font-weight: bold;
    }
    .el-select {
      margin-right: 60px;
      margin-left: 10px;
    }
    .el-input {
      width: 120px;
      margin-left: 10px;
    }
    .in1 {
      margin-right: 10px;
    }
    .in2 {
      margin-right: 60px;
    }
  }
  // table
  .tables {
    margin-left: 25px;
    margin-right: 40px;
    .el-table::before {
      height: 0;
    }
    .el-table {
      td,
      th.is-leaf {
        border: none !important;
      }
      tr th {
        font-size: 19px;
        color: #a8a8a8;
        text-align: center;
      }
      tr td {
        text-align: center;
        font-size: 17px;
        color: #a8a8a8;
      }
      .details {
        height: 30px;
        border-radius: 15px;
      }
    }
  }
  /deep/ .el-table .el-table__cell {
    padding: 12px 0;
    min-width: 0;
    box-sizing: border-box;
    text-overflow: ellipsis;
    vertical-align: middle;
    position: relative;
    text-align: center;
  }
  /deep/ .el-table td.el-table__cell,
  .el-table th.el-table__cell.is-leaf {
    border-bottom: none;
  }
  /deep/ th.el-table__cell.is-leaf {
    border-bottom: none;
  }
  // 弹框
  .el-dialog {
    border-radius: 18px;
  }
  .el-dialog__header {
    border-radius: 18px 18px 0 0;
  }
  .el-dialog__close {
    color: transparent;
    background-image: url("../../../assets/images/close.png");
    background-repeat: no-repeat;
    background-size: 14px;
  }
  .el-dialog__body {
    border-radius: 0 0 18px 18px;
    padding: 10px 20px 0 20px;
  }
  // 总结
  .total {
    display: flex;
    justify-content: end;
    margin-left: 85px;
    margin-top: 20px;
    flex-wrap: wrap;
    .right {
      display: flex;
      align-items: center;
      margin-right: 30px;
      .el-pagination {
        padding: 0;
        .el-pager,
        .el-pager li {
          color: #afafaf;
          font-size: 17px;
          font-weight: normal;
        }
        .el-pager li.active {
          color: #0085d0;
        }
        .el-pager li:hover {
          color: #0085d0;
        }
        img {
          display: inline-block;
        }
        .lefts {
          margin-right: 25px;
        }
        .rights {
          float: right;
          margin-left: 25px;
        }
      }
    }
    p {
      font-size: 15px;
      display: inline-block;
      margin-right: 80px;
      color: #606266;
    }
  }
  // 按钮
  .buttons {
    display: flex;
    justify-content: flex-end;
    margin-right: 20px;
    .el-button {
      width: 130px;
      height: 40px;
      line-height: 40px;
      border-radius: 25px;
      margin-top: 20px;
      margin-right: 20px;
      background: #0085d0;
      color: #ffffff;
      font-size: 16px;
      padding: 0px;
    }
  }
  /deep/.el-table td,
  .el-table th.is-leaf {
    border-bottom: none !important;
  }
  /deep/.el-table .cell {
    text-align: center !important;
  }
  /deep/.el-table thead tr th.is-leaf {
    border: none;
    border-right: none;
  }
}
.submitBtn {
  padding: 9px 10px;
  font-size: 14px;
  margin-top: 4%;
}
.my-awesome-json-theme {
  overflow-x: hidden;
  background: #0c2b52;
  white-space: nowrap;
  color: #01fef4;
  font-size: 14px;
  font-family: Consolas, Menlo, Courier, monospace;

  .jv-ellipsis {
    color: rgb(237, 13, 13);
    background-color: rgb(241, 11, 11);
    display: inline-block;
    line-height: 0.9;
    font-size: 0.9em;
    padding: 0px 4px 2px 4px;
    border-radius: 3px;
    vertical-align: 2px;
    cursor: pointer;
    user-select: none;
  }
  .jv-button {
    color: #49b3ff;
  }
  ::v-deep .jv-key {
    color: #01fef4 !important;
  }
  ::v-deep .jv-push {
    color: #fff;
  }
  .jv-item {
    &.jv-array {
      color: #111111;
    }
    &.jv-boolean {
      color: #fc1e70;
    }
    &.jv-function {
      color: #067bca;
    }
    &.jv-number {
      color: #fc1e70;
    }
    &.jv-number-float {
      color: #fc1e70;
    }
    &.jv-number-integer {
      color: #fc1e70;
    }
    &.jv-object {
      color: #111111;
    }
    &.jv-undefined {
      color: #e08331;
    }
    &.jv-string {
      color: #42b983;
      word-break: break-word;
      white-space: normal;
    }
  }
  .jv-code {
    ::v-deep .jv-toggle {
      color: #067bca !important;
      &:before {
        padding: 0px 2px;
        border-radius: 2px;
      }
      &:hover {
        &:before {
          background: rgb(242, 5, 5);
        }
      }
    }
  }
}
</style>